import { render, staticRenderFns } from "./Medias.vue?vue&type=template&id=18e81fd4&scoped=true&"
import script from "./Medias.vue?vue&type=script&lang=js&"
export * from "./Medias.vue?vue&type=script&lang=js&"
import style0 from "./Medias.vue?vue&type=style&index=0&id=18e81fd4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e81fd4",
  null
  
)

export default component.exports